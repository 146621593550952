import React, { useState } from "react";
import "../styles/pages/narratives.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link } from "../components/links";
import { graphql } from "gatsby";

const Narratives = ({ data }) => {
  const [isHoveringFilm, setIsHoveringFilm] = useState(false);

  const overlayColorClasses = classNames("narratives__overlay", {
    "narratives__overlay--dark": isHoveringFilm,
  });

  const allPosts = data.allContentfulWorkPost.edges;
  allPosts.sort((a, b) => {
    return new Date(b.node.dateReleased) - new Date(a.node.dateReleased);
  });


  return (
    <div className="narratives">
      {/* <section className="narratives__hero">
        <div className="narratives__hero__inner">
          <h1>Our films</h1>
        </div>
      </section> */}
      <section className="narratives__index">
        <div className="narratives__index__inner">
          <div className="narratives__index__items">
            {allPosts.map((post, index) => {
              const titleClasses = classNames(
                "narratives__index__items__item",
                {
                  "narratives__index__items__item__red-title": index % 5 === 0,
                  "narratives__index__items__item__gold-title": index % 5 === 1,
                  "narratives__index__items__item__green-title":
                    index % 5 === 2,
                  "narratives__index__items__item__blue-title": index % 5 === 3,
                  "narratives__index__items__item__purple-title":
                    index % 5 === 4,
                }
              );
              return (
                <>
                  {post.node.live && (
                    <div
                      className={titleClasses}
                      onMouseLeave={() => setIsHoveringFilm(false)}
                      onMouseOver={() => setIsHoveringFilm(true)}
                    >
                      <Link to={`/work/${post.node.contentful_id}`}>
                        <h2>{post.node.title}</h2>
                        <div
                          className="narratives__index__image"
                          key={post.node.id}
                        >
                          <GatsbyImage
                            image={post.node.coverImage.gatsbyImageData}
                            alt={post.node.title}
                            loading="eager"
                          />
                        </div>
                      </Link>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </section>
      <span className={overlayColorClasses}></span>
    </div>
  );
};

export default Narratives;

export const query = graphql`
  {
    allContentfulWorkPost {
      edges {
        node {
          coverImage {
            gatsbyImageData(
              width: 320
              quality: 70
              placeholder: DOMINANT_COLOR
            )
          }
          title
          contentful_id
          live
          dateReleased
        }
      }
    }
  }
`;
